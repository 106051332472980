import './xmodal.css'
import { IoMdClose } from 'react-icons/io'

function Xmodal({open, xmodalLabel, children, custom_xmodal, onClose}) {
    
    const handleClose = (e) => {
        if (e.target.className === 'xmodalContainer') {
            onClose()
        }
        return null
    }

    if (open) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className='xmodalContainer col-md-4' onClick={handleClose}>
                        <div className={`xmodal ${custom_xmodal}`}>
                            <div className="xmodal__head">
                                <h4>{xmodalLabel}</h4>
                                <span className='xmodal__close' onClick={onClose}><IoMdClose className='close-icon'/></span>
                            </div>
                            <br />
                            {children}
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </div>

        )
    }
    return null
}

export default Xmodal
