import React from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Rubriqueline({ id, nom }) {

    return (
        <tr>
            <td hidden={true}>{`${id}`}</td>
            <td>
                <Link 
                style={{width:'100%', display:'block', color: 'var(--black)'}} 
                to={"/rubriques/"+nom}>{nom} <AiOutlineRight /></Link>
            </td>
        </tr>
    )
}

export default Rubriqueline