import React, { useEffect, useState } from 'react';
import './newarticles.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importer les styles
import { MdKeyboardBackspace } from 'react-icons/md';
import { Timestamp, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestoreDatabase } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { BsCheck2Circle } from 'react-icons/bs';
import Xmodal from '../tools/xmodal/Xmodal';

const quillModules = {
  toolbar: [
    [{'font': []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'},
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

const quillFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

function EditArticle() {

  /*Récupérer les données de l'article */

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const articleRef = doc(firestoreDatabase, 'articles', id);
        const snapshot = await getDoc(articleRef);
        const articleData = snapshot.data();

        if (articleData) {
          setTitle(articleData.title);
          setContent(articleData.content);
        }
      } catch (error) {
        console.error("Error fetching article: ", error);
      }
    };

    fetchArticle();
  }, [id]);

    const updateArticle = async (event) => {
      event.preventDefault();
      setIsLoading(true); // Afficher le loader

      const articleDocRef = doc(firestoreDatabase, 'articles', id)
      try {
          await updateDoc(articleDocRef, {
            title: title,
            content: content,
            lastModif: Timestamp.now()
          })
          setOpen({...open, edit : true})
          setIsLoading(false);
      }
      catch (error) {
        console.error("Error updating article: ", error);
        setIsLoading(false);
      }
    };


  const handleChange = (newValue) => {
    setContent(newValue);
  };

  // const [isLoading, setIsLoading] = useState(false);


  const [open, setOpen] = useState({edit:false});

  const handleClose = () => {
    setOpen({edit:false});
    navigate(-1);
  }
  
  const navigate = useNavigate();

  return (

    <div id='newArticle'>
      
      <header className='row'>
        <div className='col-md-1' />
        <div className='col-md-1'><MdKeyboardBackspace 
         onClick={() => navigate(-1)}
        /></div>
        <div className='col-md-8'>Modifier votre article</div>
        <div className='col-md-2' />
      </header>

      <div className='row d-flex justify-content-center'>

        <form className='col-md-8' 
        onSubmit={updateArticle}
        >

      <div className='form-ctrl'>
        <label><p>Titre</p></label>
        <input
          type="text"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          required
        />
      </div>

          
          <div className='form-ctrl'>
            <label>Modifiez votre article</label>
            <ReactQuill
              value={content}
              onChange={handleChange}
              modules={quillModules}
              formats={quillFormats}
            />
          </div>
          
          <div className='btn-ctrl'>
            <button type='submit' className='post-btn' id='post-btn'>
            {isLoading ? (
            <i className="fa fa-circle-o-notch fa-spin"/>
            ) : (
            'Publier les modifications'
            )}            
            </button>
          </div>

        </form>

      </div>

      {open.edit &&      
          <Xmodal xmodalLabel={''} onClose={handleClose} open={open}>
                  <center>
                      <div className='row d-flex justify-content-center'>
                        <BsCheck2Circle className='success' />

                          <p>Votre article a bien été modifié !</p>

                          <button 
                          className='ok col-md-2' 
                          type='submit'
                          onClick={() => navigate(-1)}
                          >OK</button>

                      </div>
                  </center>
          </Xmodal>
      }

    </div>

  )
}

export default EditArticle