import './dashboard.css';
import logo from "./assets/logo/logo_vertical.png";
import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { auth, firestoreDatabase } from './firebase';
import { Dropdown } from 'react-bootstrap';
import { CiPower } from 'react-icons/ci';
import { AiOutlineUser } from 'react-icons/ai';
import { RxDashboard } from 'react-icons/rx';
import { MdLabelOutline, MdPeopleOutline } from 'react-icons/md';
import { BsCollection } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';
import Xmodal from './components/tools/xmodal/Xmodal';
import NewMemberSignUp from './NewMemberSignUp';



function Dashboard(props) {

  const user = auth.currentUser;

  const navigate = useNavigate();

  //Récupérer les données de l'utilisateur
  const [userData, setUserData] = useState(null);

  /////

  async function checkFamilyDocument(userId) {
    try {
      // Référence au document de l'utilisateur dans la collection "family"
      const userDocRef = doc(firestoreDatabase, 'family', user.uid);
  
      // Vérifiez si le document existe
      const docSnapshot = await getDoc(userDocRef);
  
      return docSnapshot.exists();
    } catch (error) {
      console.error("Erreur lors de la vérification du document de la famille : ", error);
      return false; // En cas d'erreur, considérez par défaut que le document n'existe pas
    }
  }
  
  async function checkAccess() {
  
    if (user) {
      const userId = user.uid;
      const hasAccess = await checkFamilyDocument(userId);
  
      if (!hasAccess) {
        // Redirigez l'utilisateur vers "/new-member" s'il n'a pas accès
        // navigate("/new-member");

        setOpen({...open, edit : true})
      }
    }
  }

  // New-Member info
  const [open, setOpen] = useState({edit:false});

  const handleClose = () => {
    setOpen({edit:false});
  }
  
  useEffect(() => {

    checkAccess();

    const fetchUserData = async () => {
      const q = query(collection(firestoreDatabase, "family"), 
      where("email", "==", user.email))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUserData(doc.data());
      })
    }; 
    fetchUserData();
    
    //Autorisation Verification
    if (userData?.autorisation === "Non Accordée") {
      signOut(auth);
      return navigate("/verify-autorisation");
    }

    //Déconnecter l'utilisateur après 30 minutes d'inactivité
    if (user) {
      const disconnectTimeOut = async () => {
        let timeoutId;

        function inactivityCallback() {
          signOut(auth);
          navigate("/signin");
        }

        function resetTimer() {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(inactivityCallback, 30 * 60 * 1000); // 30 * 60 * 1000 = 30 minutes
        }

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
      }
      disconnectTimeOut();
    }
  }, [ user, navigate ]);

  const myProfilPicture={backgroundImage:`url(${userData?.imgprofil})`};

  const [isLoading, setIsLoading] = useState(false);
  const handleSignOut = async () => {
    setIsLoading(true); // Afficher le loader

    try {
      // Attendre deux secondes avant de déclencher la déconnexion
      setTimeout(async () => {
        await signOut(auth);
        // Terminé, masquer le loader et naviguer vers "/signin"
        setIsLoading(false);
        navigate("/signin");
      }, 2000);
    } catch (error) {
      // Gérer les erreurs, si nécessaire
    }
  };

  return (

  <div className='container-fluid'>

    <div className="row">
      <div className="col-md-2" id="sidenav">

      <div className='' id='logo_cont'>
        <img src={logo} id='logo' alt="" />
      </div>

        <ul id="sidenav_list">

          <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>
            <RxDashboard/> Dashboard
          </NavLink>

          <NavLink to="/members" className={({ isActive }) => isActive ? 'active' : ''}>
            <MdPeopleOutline/> Membres
          </NavLink>

          <NavLink to="/articles" className={({ isActive }) => isActive ? 'active' : ''}>
            <IoDocumentTextOutline/> Articles
          </NavLink>

          <NavLink to="/collections" className={({ isActive }) => isActive ? 'active' : ''}>
            <BsCollection/> Collections
          </NavLink>

          <NavLink to="/rubriques" className={({ isActive }) => isActive ? 'active' : ''}>
            <MdLabelOutline/> Rubriques
          </NavLink>

        </ul>

        <ul id='sidenav_bottom'>
          <button onClick={handleSignOut}>
            {isLoading ? (
              <>
              <i className="fa fa-circle-o-notch fa-spin" /> Déconnexion
              </>
            ) : (
              <>
                <CiPower /> Déconnexion
              </>
            )}
          </button>
        </ul>

      </div>

      <div className='col-md-10 main container-fluid'>
        <header className='row'>
        <div className='col-md-10'></div>
        <div className='col-md-2'>
          <ul id='header_right' className=''>

            <div id='my_profile'>
                <Dropdown>
                  <Dropdown.Toggle>
                    {userData?.imgprofil ? (
                      <div className="profil_picture" id=""
                      style={myProfilPicture} >
                      </div>
                    ) : (
                      <div className="profil_picture" id="noProfilPicture" />
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={ () => navigate("/myprofile")}>
                      <div><AiOutlineUser /> Profil</div>
                    </Dropdown.Item>

                    <center>
                      <hr />
                    </center>

                  </Dropdown.Menu>
                </Dropdown>
            </div>

          </ul>
        </div>
        </header>

        {props.children}

              {open.edit &&      
          <Xmodal xmodalLabel={'Bienvenue sur family.Connect'} onClose={handleClose} open={open}>
                  <center>
                      <div className='row d-flex justify-content-center'>
                        
                      <NewMemberSignUp />


                      </div>
                  </center>
          </Xmodal>
      }

      </div>
    </div>

  </div>
  )
}

export default Dashboard