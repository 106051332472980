import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Signin from './Signin';
import AccountError from './AccountError';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import {AuthProvider} from './AuthContext';
import $ from 'jquery';
import PasswordRecovery from './PasswordRecovery';
import MyProfile from './components/myprofile/MyProfile';
import Index from './components/Index';
import VerifyEmail from './VerifyEmail';
import VerifyAutorisation from './VerifyAutorisation';
import Members from './components/members/Members';
import Articles from './components/articles/Articles';
import Collections from './components/collections/Collections';
import Rubriques from './components/rubriques/Rubriques';
import ArticleView from './components/articles/ArticleView';
import Board from './components/board/Board';
import NewMemberSignUp from './NewMemberSignUp';
import PrivateRoute from './PrivateRoute';
import NewArticle from './components/articles/NewArticle';
import Rubrique from './components/rubriques/Rubrique';
import EditArticle from './components/articles/EditArticle';

function App() {

    /*Page Loader*/
    $(document).ready(function() {
 
      // Fakes the loading setting a timeout
        setTimeout(function() {
            $('body').addClass('loaded');
        }, 2000);
     
    });

  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])

  return (
    
    <Router>

      <div id="loader-wrapper">
        <div id="loader"></div>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>

      <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
          
          <Routes>

            <Route path='/' element={
              <PrivateRoute>
                <Index/>
              </PrivateRoute>
            } 
            />

            <Route path='/signin' element={
              !currentUser?.emailVerified
              ?<Signin />
              : <Navigate to='/' replace/>
              } />

            <Route path='/new-member' element={
              <PrivateRoute>
                <NewMemberSignUp/>
              </PrivateRoute>
              } />

            <Route path='/verify-email' element={
              <PrivateRoute>
                <VerifyEmail/>
              </PrivateRoute>             
              } /> 

            <Route path='/verify-autorisation' element={
              <PrivateRoute>
                <VerifyAutorisation/>
              </PrivateRoute>            
              } /> 

            <Route path='/accountError' element={<AccountError/>} />

            <Route path='/passwordrecovery' element={<PasswordRecovery/>} />

            <Route path='/board'  element={<Board />} />

            <Route path='/myprofile' element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            } />

            <Route path='/articles' element={
              <PrivateRoute>
                <Articles />
              </PrivateRoute>
            } />

            <Route path='/new-article' element={
              <PrivateRoute>
                <NewArticle />
              </PrivateRoute>
            } />

            <Route path="/articles/:id" element={
              <PrivateRoute>
                <ArticleView />
              </PrivateRoute>
            } />

            <Route path="/edit-article/:id" element={
              <PrivateRoute>
                <EditArticle />
              </PrivateRoute>
            } />

            <Route path='/members' element={
              <PrivateRoute>
                <Members />
              </PrivateRoute>
            } />

            <Route path='/collections' element={
              <PrivateRoute>
                <Collections />
              </PrivateRoute>
            } />

            <Route path='/rubriques' element={
              <PrivateRoute>
                <Rubriques />
              </PrivateRoute>
            } />

            <Route path="/rubriques/:nom" element={
              <PrivateRoute>
                <Rubrique />
              </PrivateRoute>
            } />

          </Routes>

      </AuthProvider>

    </Router>

  );
}

export default App;