import React from 'react'
import './collections.css';
import Dashboard from '../../Dashboard'
import soon from '../../assets/images/utils/soon_coming.svg'

function Collections() {
  return (
    <Dashboard>
      <div className='text-center' id='collections'>
        <img src={soon} alt="" />
        
        <p>A Venir Prochainement !</p>

      </div>
    </Dashboard>
  )
}

export default Collections