import React, { useEffect, useState } from 'react';
import './rubriques.css';
import { Link, useParams } from 'react-router-dom';
import Dashboard from '../../Dashboard';
import SingleArticle from '../articles/SingleArticle';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDatabase } from '../../firebase';
import { MdKeyboardBackspace } from 'react-icons/md';

function Rubrique() {
    const { nom } = useParams();
    const [articles, setArticles] = useState([]);
    const [rubrique, setRubrique] = useState([]);

    //read rubrique & articles data from firebase
    useEffect(() => {
      const readRubriqueData = async () => {
          const rubriqueRef = query(collection(firestoreDatabase, 'rubriques'), where('nom', '==', nom))
          onSnapshot(rubriqueRef, (snapshot) => {
            setRubrique(snapshot.docs.map(doc => ({
              id: doc.id,
              data: doc.data()
            })))
          })
        };

      const readArticlesData = async () => {
      const articlesRef = query(collection(firestoreDatabase, 'articles'), where('rubrique', '==', nom), where('isVisible', '==', true), orderBy('createdAt', 'desc'))
      onSnapshot(articlesRef, (snapshot) => {
        setArticles(snapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })))
      })
    };

    readRubriqueData();
    readArticlesData();
    }, []);

    const rubriqueCover = {backgroundImage:`url(${rubrique[0]?.data?.cover})`};

  return (
    <Dashboard>
        <div id='rubrique'>
            <div className='row'>
                <div className="jumbotron cover" style={rubriqueCover}>
                    <div className='row'>
                        <div className='back'>
                            <Link to={'../rubriques/'}>
                                <MdKeyboardBackspace />                          
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            
            <br/>
            <h4>Rubrique <span className='fw-bold'>{nom}</span></h4>
            <div className='red-divider'></div>

            <div>
                {articles !== null ? (
                    articles.map((article) => {
                        return (
                        <SingleArticle
                        id={article.id}
                        key={article.id}
                        coverUrl={article.data.coverUrl}
                        title={article.data.title}
                        rubrique={article.data.rubrique}
                        createdAt={(new Date(article.data.createdAt.seconds * 1000).toLocaleDateString("fr"))}
                        />
                        )
                    })
                ) : (
                    <p className='number'>Aucun article n'a été publié dans cette</p>
                )}

            </div>

        </div>

    </Dashboard>  
    )
}

export default Rubrique