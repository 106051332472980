import React, { useEffect, useState } from 'react';
import './articles.css';
import Dashboard from '../../Dashboard';
import SingleArticle from './SingleArticle';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { firestoreDatabase } from '../../firebase';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Styles par défaut, vous pouvez les personnaliser


function Articles() {

  const [articles, setArticles] = useState([]);
  const [archives, setArchives] = useState([]);

  //read articles data from firebase
  useEffect(() => {
  const readArticlesData = async () => {
    const articlesRef = query(collection(firestoreDatabase, 'articles'), orderBy('createdAt', 'desc'), where('isVisible', '==', true))
    onSnapshot(articlesRef, (snapshot) => {
      setArticles(snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  };
  readArticlesData();

  const readArchivesData = async () => {
    const articlesRef = query(collection(firestoreDatabase, 'articles'), orderBy('lastModif', 'desc'), where('isVisible', '==', false))
    onSnapshot(articlesRef, (snapshot) => {
      setArchives(snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  };
  readArchivesData();

  }, []);


  return (
      <Dashboard>

        <Tabs>
          <TabList>
            <Tab>Articles publiés</Tab>
            <Tab>Articles archivés</Tab>
          </TabList>

          <TabPanel>
          {articles.map((article) => {
              return (
              <SingleArticle
              id={article.id}
              key={article.id}
              coverUrl={article.data.coverUrl}
              title={article.data.title}
              rubrique={article.data.rubrique}
              />
              )
            })}
          </TabPanel>

          <TabPanel>
            
          {archives.map((archive) => {
              return (
              <SingleArticle
              id={archive.id}
              key={archive.id}
              coverUrl={archive.data.coverUrl}
              title={archive.data.title}
              rubrique={archive.data.rubrique}
              />
              )
            })}
          </TabPanel>
        </Tabs>

          <Link to={'/new-article'} className='new-article'>
            <FaPlus />
          </Link>

      </Dashboard>
  )
}

export default Articles