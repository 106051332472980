import React, { useEffect, useState } from 'react';
import './articleview.css';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { firestoreDatabase } from '../../firebase';
import { Timestamp, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { MdKeyboardBackspace } from 'react-icons/md';
import { AiFillQuestionCircle } from 'react-icons/ai';
import Xmodal from '../tools/xmodal/Xmodal';

function ArticleView() {

    const navigate = useNavigate();

    const { id } = useParams();
    const [ article, setArticle ] = useState([]);
    const [ isVisible, setIsVisible ] = useState([]);

    useEffect(() => {
        const readThisArticleData = async () => {
            const docRef = doc(firestoreDatabase, 'articles', id);
            const docSnap = await getDoc(docRef);
            setArticle(docSnap.data());
            setIsVisible(docSnap.data().isVisible);
        }; 
        readThisArticleData();
      }, [id]);

    const articleCover = {backgroundImage:`url(${article?.coverUrl})`};

    //Archiver un article
    const [isArchiving, setIsArchiving] = useState(false);

    const archivateArticle = async (event) => {
        event.preventDefault();
        setIsArchiving(true); // Afficher le loader
      
        const articleDocRef = doc(firestoreDatabase, 'articles', id);
      
        try {
          // Attendre la mise à jour de l'article
          await updateDoc(articleDocRef, {
            isVisible: false,
            lastModif: Timestamp.now(),
          });
      
          // La mise à jour de l'article est réussie, nous pouvons réinitialiser setIsArchiving
          setIsArchiving(false);
          navigate(-1);
        } catch (error) {
          setIsArchiving(false);
          console.error("Error updating article: ", error);
          // Vous pouvez ajouter une logique de gestion d'erreur ici, comme afficher un message d'erreur à l'utilisateur.
        }
      };
      
    //Désarchiver un article
    const [isDisarchiving, setIsDisarchiving] = useState(false);

      const desarchivateArticle = async (event) => {
        event.preventDefault();
        setIsDisarchiving(true); // Afficher le loader
      
        const articleDocRef = doc(firestoreDatabase, 'articles', id);
      
        try {
          // Attendre la mise à jour de l'article
          await updateDoc(articleDocRef, {
            isVisible: true,
            lastModif: Timestamp.now(),
          });
      
          // La mise à jour de l'article est réussie, nous pouvons réinitialiser setIsArchiving
          setIsDisarchiving(false);
          navigate(-1);
        } catch (error) {
            setIsDisarchiving(false);
          console.error("Error updating article: ", error);
          // Vous pouvez ajouter une logique de gestion d'erreur ici, comme afficher un message d'erreur à l'utilisateur.
        }
      };

    //Supprimer un article
    const deleteArticle = async () => {
        try {
          // Vérifiez si l'ID de l'article est valide
          if (!id) {
            console.error("L'ID de l'article n'est pas défini.");
            return;
          }
    
          // Accédez au document de l'article dans Firestore
          const articleDocRef = doc(firestoreDatabase, 'articles', id);
    
          // Supprimez le document de l'article
          await deleteDoc(articleDocRef);
          navigate(-1);
    
          // Vous pouvez également effectuer d'autres actions après la suppression, comme rediriger l'utilisateur ou afficher un message de confirmation.
        } catch (error) {
          console.error("Erreur lors de la suppression de l'article : ", error);
          // Gérez l'erreur, par exemple en affichant un message d'erreur à l'utilisateur.
        }
      };

      const [open, setOpen] = useState({edit:false});

      const handleClose = () => {
        setOpen({edit:false});
      }
      


    return (
            <div className="articleView container-fluid" id='articleView'>
                <div className='row'>
                    <div className="jumbotron cover" style={articleCover}>
                        <div className='row head'>
                            <div className='col-md-1 back'>
                                <MdKeyboardBackspace
                                    onClick={() => navigate(-1)} />
                            </div>

                            <div className='col-md-3 actions'>
                            <Link 
                            to={"/edit-article/"+id}
                            className='action' 
                            >Modifier</Link>

                            {isVisible ? (
                            <button
                            onClick={archivateArticle}
                            className='action'
                            type='button'
                            >
                            {isArchiving ? (
                            <i className="fa fa-circle-o-notch fa-spin"/>
                            ) : (
                            'Archiver'
                            )}                                
                            </button>

                            ) : (

                            <button
                            onClick={desarchivateArticle}
                            className='action' 
                            type='button'
                            >
                            {isDisarchiving ? (
                            <i className="fa fa-circle-o-notch fa-spin"/>
                            ) : (
                            'Désarchiver'
                            )}      
                            </button>
                            )}

                            {isVisible === false && (
                            <button 
                            className='action' 
                            type='button'
                            onClick={() => setOpen({edit:true})}
                            >
                            Supprimer
                            </button>
                            )}

                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-8'>
                            <p className='title'>{article?.title}</p>
                            <div className='red-divider'></div>
                            <p className="rubrique">{article?.rubrique}</p>
                            <p className="time">Publié le {article?.createdAt?.toDate().toLocaleString()}</p>                            
                        </div>
                    </div>
                </div>

                <div className='container-fluid article_text'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-md-8 content' dangerouslySetInnerHTML={{ __html: article?.content }}></div>
                    </div>
                </div>

                {open.edit &&      
                <Xmodal xmodalLabel={''} onClose={handleClose} open={open}>
                        <center>
                            <div className='row d-flex justify-content-center'>
                                <AiFillQuestionCircle className='quest' />

                                <p>Confirmez-vous la suppression de cet article ?</p>

                                <button 
                                className='confirm col-md-4' 
                                type='submit'
                                onClick={deleteArticle}
                                >Oui, je confirme</button>

                            </div>
                        </center>
                </Xmodal>
                }

            </div>
    )

}

export default ArticleView