import './verifyEmail.css'
import {useAuthValue} from './AuthContext'
import {useState, useEffect, useRef} from 'react'
import {auth} from './firebase'
import {sendEmailVerification} from 'firebase/auth'
import {useNavigate} from 'react-router-dom'
import Xmodal from './components/tools/xmodal/Xmodal'

function VerifyEmail() {

  const [openXmodal, setOpenXmodal] = useState(false)
  const message = useRef();

  const {currentUser} = useAuthValue()
  const [time, setTime] = useState(60)
  const {timeActive, setTimeActive} = useAuthValue()
  const navigate = useNavigate()

  useEffect(() => {    
    const interval = setInterval(() => {
      currentUser?.reload()
      .then(() => {
        if(currentUser?.emailVerified){
          clearInterval(interval)
          navigate('/verify-autorisation')
        }
      })
      .catch((error) => {
        message.current = error;
        setOpenXmodal(true)
        })
    }, 1000) 
  }, [navigate, currentUser])

  useEffect(() => {
    let interval = null
    if(timeActive && time !== 0 ){
      interval = setInterval(() => {
        setTime((time) => time - 1)
      }, 1000)
    }else if(time === 0){
      setTimeActive(false)
      setTime(60)
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  }, [timeActive, time, setTimeActive])

  const resendEmailVerification = () => {
    sendEmailVerification(auth.currentUser)
    .then(() => {
      setTimeActive(true)
    }).catch((error) => {
      message.current = error;
      setOpenXmodal(true)
    })
  }

  return (
    <body id='verifyMail'>
      <div className='center'>
        {openXmodal &&
          <Xmodal onClose={() => setOpenXmodal(false)}
            open={openXmodal}
            children={message}
          />}

        <div className='verifyEmail col-md-6 text-center'>
          <h2>Authentifiez votre adresse email</h2>
          <p>
            <strong>Un mail de vérification a été envoyé à:</strong><br/>
            <span>{currentUser?.email}</span>
          </p>
          <span>Suivez les instructions afin d'authentifier votre compte</span>       
          <button 
            onClick={resendEmailVerification}
            disabled={timeActive}
          >Renvoyer le mail de vérification {timeActive && time}</button>
        </div>
      </div> 
    </body>
  )
} 

export default VerifyEmail
