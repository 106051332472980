import React, { useEffect, useState } from 'react';
import Dashboard from '../../Dashboard';
import './board.css';
import { firestoreDatabase } from '../../firebase';
import { collection, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { AiOutlineRight } from 'react-icons/ai';

function Board() {

  const [membersCount, setMembersCount] = useState(null);
  const [articlesCount, setArticlesCount] = useState(null);
  const [collectionsCount, setCollectionsCount] = useState(null);
  const [rubriquesCount, setRubriquesCount] = useState(null);
  const [lastArticle, setLastArticle] = useState([]);

  useEffect(() => {
    //members
    const m = query(collection(firestoreDatabase, "family"));
    getDocs(m)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setMembersCount(count);
      })
      .catch((error) => {
      });

    //articles
    const a = query(collection(firestoreDatabase, "articles"), where('isVisible', '==', true));
    getDocs(a)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setArticlesCount(count);
      })
      .catch((error) => {
      });

    //collections
    const c = query(collection(firestoreDatabase, "collections"));
    getDocs(c)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setCollectionsCount(count);
      })
      .catch((error) => {
      });

    //rubriques
    const r = query(collection(firestoreDatabase, "rubriques"));
    getDocs(r)
      .then((querySnapshot) => {
        const count = querySnapshot.size;
        setRubriquesCount(count);
      })
      .catch((error) => {
      });

    //read articles data from firebase
    const readLastArticleData = async () => {
      const articlesRef = query(collection(firestoreDatabase, 'articles'), orderBy('createdAt', 'desc'), where('isVisible', '==', true), limit(1))
      onSnapshot(articlesRef, (snapshot) => {
        setLastArticle(snapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })))
      })
    };
    readLastArticleData();
  }, []);

  const lastArticleCover = { backgroundImage: `url(${lastArticle[0]?.data?.coverUrl})` };

  return (
    <Dashboard>

      <div className='container-fluid board-cont '>

        <div className='row board'>

          <div className='col-md-6' id='last_article'
            style={lastArticleCover}>
            <div className='title_link'>
              <p>{lastArticle[0]?.data?.title} 
              <Link to={"/articles/" + lastArticle[0]?.id}> ... Lire <AiOutlineRight /></Link>
              </p>
              
            </div>
          </div>

          <div className='col-md-5'>

            <div className='row line_one'>

              <div className='col-md-6 box' id='members_count'>
                {membersCount !== null ? (
                  <p className='number'>{membersCount}</p>
                ) : (
                  <p className='number'>...</p>
                )}
                <p className='item' s>Membre.s</p>
              </div>


              <div className='col-md-6 box' id='articles_count'>
                {articlesCount !== null ? (
                  <p className='number'>{articlesCount}</p>
                ) : (
                  <p className='number'>...</p>
                )}
                <p className='item'>Article.s publié.s</p>
              </div>

            </div>

            <div className='row line_two'>

              <div className='col-md-6 box' id='collections_count'>
                {collectionsCount !== null ? (
                  <p className='number'>{collectionsCount}</p>
                ) : (
                  <p className='number'>...</p>
                )}
                <p className='item'>Collection.s</p>
              </div>


              <div className='col-md-6 box' id='rubriques_count'>
                {rubriquesCount !== null ? (
                  <p className='number'>{rubriquesCount}</p>
                ) : (
                  <p className='number'>...</p>
                )}
                <p className='item'>Rubrique.s</p>
              </div>

            </div>


          </div>

        </div>

      </div>

    </Dashboard>
  )
}

export default Board