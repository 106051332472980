import './verifyAutorisation.css'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Xmodal from './components/tools/xmodal/Xmodal'
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestoreDatabase } from './firebase'

function VerifyAutorisation() {

  const [openXmodal, setOpenXmodal] = useState(false)
  let message = "";

  const navigate = useNavigate()
  const user = auth.currentUser;
  const [autorisation, setAutorisation] = useState("");

  const verifyAuth = async () => {
    try {
      const userDocRef = doc(firestoreDatabase, 'family', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userInfo = userDocSnap.data();
        setAutorisation(userInfo.autorisation)
      }
    } catch (error) {
      message = error;
    }
  };

  verifyAuth();

  
  if (autorisation === "Accordée") {
    return navigate("/");
  }

  return (
      <body id='verifyAuth'>
        <div className='center'>
          {openXmodal &&
            <Xmodal onClose={() => setOpenXmodal(false)}
              open={openXmodal}
              children={message}
            />}

          <div className='verifyAutorisation col-md-5 text-center'>
            <h2>Autorisation Non Accordée</h2>
            <p className='fw-bold'>Votre compte n'a pas encore été autorisé par l'administrateur de cet espace.</p>
            <span>Revenez plus tard !</span>
            <Link to='/signin'>OK</Link>
          </div>
        </div>      
      </body>

  )
} 

export default VerifyAutorisation