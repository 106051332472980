import React, { useEffect, useState } from 'react';
import './newmember.css';
import { auth, firestoreDatabase, storage } from './firebase';
import { doc, Timestamp, setDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

function NewMemberSignUp() {

  const user = auth.currentUser;
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
 
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  //Checking UserData status

  const [file, setFile] = useState(null);

  const addProfilInfo = async (event) => {
      // Le input ne contient pas de caractères spéciaux
      event.preventDefault();
      setIsLoading(true); // Afficher le loader
      
      // Télécharger la photo de profil sur Firebase Storage
      const fileRef = ref(storage, `family/profil//${file.name}`);
      await uploadBytes(fileRef, file);
      
      // Récupérer l'URL de téléchargement de la photo de profil
      const downloadURL = await getDownloadURL(fileRef);
      
      // Enregistrer l'URL de téléchargement dans Firestore
      await setDoc(doc(firestoreDatabase, "family", user.uid), {
        lastName,
        firstName,
        email: user.email,
        imgprofil: downloadURL,
        usertype: 'family',
        role: 'administrator',
        autorisation: true,
        creationDate: user.metadata.creationTime,
        lastModif: Timestamp.now()
      });
      setIsLoading(false);
      navigate("/myprofile");

  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
      <div id='new_member_container' className=''>

        <div className='row d-flex justify-content-center info'>

          <div className='col-md-12'>

            <form onSubmit={addProfilInfo}>

              <div className='add_profil_pic'>
                <center>
                    <label>
                    <p className='info_type text-center'>Choisissez une photo de profil</p>
                    <input type="file" onChange={handleFileChange} />
                    </label>
                </center>
              </div>

              <hr/>

              <p className='info_type text-center'>Vos informations personnelles</p>

              <center>

              <input
                  type="text"
                  placeholder="Prénoms"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required/>

                <input
                  type="text"
                  placeholder="Nom"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required/>

                <button type='submit' className="btn-form">
                {isLoading ? (
                    <i className="fa fa-circle-o-notch fa-spin"/>
                    ) : (
                    "Enregistrer"
                )}
                </button>
                
              </center>
            </form>
          </div>

        </div>

      </div>
  )
}

export default NewMemberSignUp