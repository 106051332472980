import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { MdCheckCircle } from 'react-icons/md'

function Userline({id, firstName, lastName, email, role, autorisation, creationDate}) {

  return (
    <tr>
        <td hidden={true}>{`${id}`}</td>
        <td>{lastName}</td>
        <td>{firstName}</td>
        <td>{email}</td> 
        <td>{role}</td>
        <td className='access_status'>
          {autorisation ? (
              <MdCheckCircle className='access_true' />
              ) : (
              <AiFillCloseCircle className='access_false' />
          )}
        </td>
        <td>{creationDate}</td>
    </tr>
)
}

export default Userline