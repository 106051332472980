import React, { useState } from 'react';
import './signin.css';
import logo from './assets/logo/logo_vertical.png'
import { auth } from './firebase';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';

function Signin() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    let message = "";

    const signIn = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Afficher le loader
    
        try {
          document.getElementById("signin-btn").innerHTML =
            '<i className="fa fa-circle-o-notch fa-spin d-none"/>';
          $('.fa-circle-o-notch').removeClass('d-none');
    
          // Attendre deux secondes avant d'appeler signInWithEmailAndPassword
          setTimeout(async () => {
            await signInWithEmailAndPassword(auth, email, password);
            // Terminé, masquer le loader
            setIsLoading(false);
            navigate("/");
          }, 2000);
        } catch (error) {
          const errorMessage = error.message;
          message = errorMessage;
          setIsLoading(false); // Masquer le loader en cas d'erreur
        }
      };
      

    return (

        <div className='container-fluid'>
            <div className='row'>

                <div className='col-md-7 left-side' />

                <div className="col-md-5 signin-form-block">
                    <div className='content'>

                        <div className="row">
                            <div className="col-md-2" />
                            <div className="col-md-8">
                                <center>
                                    <img className="logo" src={logo} alt=""/>
                                </center>
                            </div>
                            <div className="col-md-2" />
                        </div>

                        <div className="row">
                            <div className="col-md-2" />
                            <div className="col-md-8">
                                <p className="text-center small-title">Content de vous revoir !</p>
                            </div>
                            <div className="col-md-2" />
                        </div>

                        <div className="row">
                            <div className="col-md-2" />
                            <div className="col-md-8">
                                <p className="text-center smaller-title">
                                    Veuillez remplir vos informations de connexion !
                                </p>
                            </div>
                            <div className="col-md-2" />
                        </div> 

                        <br/>
                            <hr/>
                        <br/>
                        
                        <form onSubmit={signIn}>
                            <center>
                                <input
                                    type="text"
                                    value={email}
                                    placeholder="Adresse mail"
                                    onChange={(event) => setEmail(event.target.value)}
                                    required />

                                <input
                                    type="password"
                                    value={password}
                                    placeholder="Mot de passe"
                                    onChange={(event) => setPassword(event.target.value)}
                                    required />

                                <button type='submit' className="btn-form" id='signin-btn'>
                                {isLoading ? (
                                    <i className="fa fa-circle-o-notch fa-spin"/>
                                    ) : (
                                    'Connexion'
                                )}
                                </button>

                                <div className="row">
                                    <div className="col-md-2" />
                                    <div className="col-md-8">
                                        <center>
                                            <span>{message}</span>
                                        </center>
                                    </div>
                                    <div className="col-md-2" />
                                </div>

                                <Link className='forgot_password' to='/passwordrecovery'>
                                    <span>Mot de passe oublié ?</span>
                                </Link> 
                                
                            </center>
                        </form>

                    </div>

                </div>

            </div>
        </div>

    )
}

export default Signin