import React from 'react';
import './singlearticle.css';
import { Link } from 'react-router-dom';

function SingleArticle({id, coverUrl, title, rubrique}) {

  return (
    <div className='row d-flex justify-content-center'>
      <Link className='col-md-8 ' to={"/articles/"+id}>

        <div className='single_article' key={id}
          style={{ backgroundImage: `url(${coverUrl})` }}>

              <div className='content'>
                  <p className='title'>
                    <span>"</span>
                    {title}
                    <span>"</span>
                    </p>

                  <p className='rubric'>{rubrique}</p>
              </div>

        </div>

      </Link>
    </div>
  )
}

export default SingleArticle