import React, { useEffect, useState } from 'react';
import './newarticles.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importer les styles
import { MdKeyboardBackspace } from 'react-icons/md';
import { addDoc, collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { firestoreDatabase, storage } from '../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import RubriqueOption from './RubriqueOption';
import $ from 'jquery';
import { BsCheck2Circle } from 'react-icons/bs';
import Xmodal from '../tools/xmodal/Xmodal';

const quillModules = {
  toolbar: [
    [{'font': []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'},
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

const quillFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

function NewArticle() {

  const [title, setTitle] = useState('');
  const [cover, setCover] = useState('');
  const [rubrique, setRubrique] = useState('');
  const [content, setContent] = useState('');

  const handleCover = (event) => {
    setCover(event.target.files[0]);
  };
  const handleChange = (newValue) => {
    setContent(newValue);
  };

  const [open, setOpen] = useState({edit:false});

  const handleClose = () => {
    setOpen({edit:false});
  }

  const [isLoading, setIsLoading] = useState(false);

  const addNewArticle = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true); // Afficher le loader

      document.getElementById("post-btn").innerHTML = '<i className="fa fa-circle-o-notch fa-spin d-none"/>';
      $('.fa-circle-o-notch').removeClass('d-none');

      // Télécharger la photo de profil sur Firebase Storage
      const fileRef = ref(storage, `article_covers/${cover.name}`);
      await uploadBytes(fileRef, cover);

      // Récupérer l'URL de téléchargement de la photo de profil
      const coverUrl = await getDownloadURL(fileRef);
  
      // Adding the article to Firebase Firestore
      await addDoc(collection(firestoreDatabase, "articles"), {
        title: title,
        coverUrl: coverUrl,
        rubrique: rubrique,
        content: content,
        isVisible: true,
        createdAt: new Date(),
      });

      document.getElementById("post-btn").innerHTML = '<i className="fa fa-circle-o-notch fa-spin d-none"/>Publier';

      setOpen({...open, edit : true})

      setContent('');
      setTitle('');
      setCover('');

    } catch (error) {
      console.error("Error adding article: ", error);
    }
  };
  
  const navigate = useNavigate();

  //read rubriques data
  const [rubriqueOptions, setRubriqueOptions] = useState([])
  useEffect(() => {
    const rubriqueOptionRef = query(collection(firestoreDatabase, 'rubriques'), orderBy('nom', 'asc'))
    onSnapshot(rubriqueOptionRef, (snapshot) => {
      setRubriqueOptions(snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }, [])

  return (

    <div id='newArticle'>
      
      <header className='row'>
        <div className='col-md-1' />
        <div className='col-md-1'><MdKeyboardBackspace 
         onClick={() => navigate(-1)}
        /></div>
        <div className='col-md-8'>Nouvel Article</div>
        <div className='col-md-2' />
      </header>

      <div className='row d-flex justify-content-center'>

        <form className='col-md-8' onSubmit={addNewArticle}>

          <div className='form-ctrl'>
            <label><p>Titre</p></label>
            <input type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}     
            required       
            />
          </div>

          <div className='form-ctrl'>
            <label><p className=''>Photo de couverture</p></label>
            <input type="file"
            onChange={handleCover}
            required
            />
          </div>

          <div className='form-ctrl'>
            <label><p className=''>Rubrique</p></label>
            <select 
              value={rubrique}
              onChange={(e) => setRubrique(e.target.value)}
              required>
                <option value="" selected>...</option>
                {rubriqueOptions.map((rubriqueOption) => {
                  return (
                    <RubriqueOption
                    id={rubriqueOption.id}
                    key={rubriqueOption.id}
                    nom={rubriqueOption.data.nom}
                    />
                  )
                })}
            </select>
          </div>
          
          <div className='form-ctrl'>
            <label>Ecrivez votre article</label>
            <ReactQuill
              value={content}
              onChange={handleChange}
              modules={quillModules}
              formats={quillFormats}
            />
          </div>
          
          <div className='btn-ctrl'>
            <button type='submit' className='post-btn' id='post-btn'>
            {isLoading ? (
            <i className="fa fa-circle-o-notch fa-spin"/>
            ) : (
            'Publier'
            )}
            </button>
          </div>

        </form>

      </div>

      {open.edit &&      
          <Xmodal xmodalLabel={''} onClose={handleClose} open={open}>
                  <center>
                      <div className='row d-flex justify-content-center'>
                        <BsCheck2Circle className='success' />

                          <p>Votre article a bien été ajouté !</p>

                          <button 
                          className='ok col-md-2' 
                          type='submit'
                          onClick={handleClose}
                          >OK</button>

                      </div>
                  </center>
          </Xmodal>
      }

    </div>

  )
}

export default NewArticle