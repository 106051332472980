import React, { useState } from 'react';
import './passwordrecovery.css';
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase';
import Xmodal from './components/tools/xmodal/Xmodal';

function PasswordRecovery() {

  const [email, setEmail] = useState('');

  const [openXmodal, setOpenXmodal] = useState(false);
  let message = "Un mail de réinitialisation a été envoyé sur votre adresse email !"

  const passwordResetMail = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      message = error;
    }
    setOpenXmodal(true);
  }

  return (
      <div className='row d-flex'>

      <div className='col-md-3 left_background' />
      <div className='col-md-1' />

      <div className="col-md-4 form-block align-middle">
          <div>

              <div className="row">
                  <div className="col-md-2" />
                  <div className="col-md-8">
                      <p className="text-center small-title">Mot de passe oublié ?</p>
                  </div>
                  <div className="col-md-2" />
              </div>

            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <p className="text-center smaller-title">
                Entrez l'adresse e-mail que vous avez utilisée pour créer votre compte Connect. Vous y recevrez un mail pour réinitialiser votre mot de passe.
                </p>
              </div>
              <div className="col-md-2" />
            </div> 

              <form onSubmit={passwordResetMail}>
                  <center>
                      <input
                          type="text"
                          value={email}
                          placeholder="Adresse mail"
                          onChange={(event) => setEmail(event.target.value)}
                          required />

                      <button type='submit' className="btn-form">Envoyer un e-mail de réinitialisation</button>

                      {openXmodal &&
                      <Xmodal onClose={() => setOpenXmodal(false)}
                      open={openXmodal}
                      children={message}
                      />
                      }

                      <Link className='back_to_signin' to='/signin'>
                        <span>Retourner à la page de connexion</span>
                      </Link> 

                  </center>
              </form>

          </div>
      </div>

      <div className='col-md-1' />
      <div className='col-md-3 right_background' />

      </div>
  )
}

export default PasswordRecovery