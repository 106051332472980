import React, { useEffect, useState } from 'react';
import './members.css';
import Dashboard from '../../Dashboard';
import {Modal, Table} from 'react-bootstrap';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { auth, firestoreDatabase } from '../../firebase';
import Userline from './Userline';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import $ from 'jquery';

function MyVerticallyCenteredModal(props) {

  const [email, setEmail] = useState("");
  const password = 'univers';
  const [error, setError] = useState('');

  const addMember = async (e) => {
    e.preventDefault()
    setError('')
      //user creation
      await createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        sendEmailVerification(auth.currentUser)
      })
      .catch(error => setError(error.message))

      $('#addmemberform').addClass('d-none');
      $('#successmessage').removeClass('d-none');

      setEmail('')
  }
return (
  <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Ajouter un membre
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className='row justify-content-center'>
    {error && <div className='auth__error'>{error}</div>}

            <form onSubmit={addMember} name='addMember_form' className='col-md-12' id='addmemberform'>
              <center>

                <input
                  type="email"
                  placeholder="Adresse mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required/>

                <input
                  type="password"
                  placeholder="Mot de passe"
                  value={password}
                  required/>

                <button type='submit' className="btn-form">Ajouter
                </button>
                
              </center>
            </form>

            <center id='successmessage' className='d-none'>
              <p>Nouveau membre ajouté !</p>
              <button type='button' className="btn-form" onClick={props.onHide}>OK</button>
            </center>

    </Modal.Body>
  </Modal>
);
}

function Members() {

    const [members, setMembers] = useState([])

    //read users data from firestore
    useEffect(() => {
    const retrieveUsersData = async () =>{
      const userColRef = query(collection(firestoreDatabase, 'family'), orderBy('creationDate', 'desc'))
      onSnapshot(userColRef, (snapshot) => {
        setMembers(snapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })))
      })
    }

    retrieveUsersData();
      }, []);

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <Dashboard>

          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />

          <div className="row" id='members'>

            <div className="col-md-1"></div>

            <div className="col-md-10">

              <Table hover className="list_table">
                <thead>
                  <tr>
                    <th hidden={true}>id</th>
                    <th>Nom</th>
                    <th>Prénom(s)</th>
                    <th>Email</th>
                    <th>Rôle</th>
                    <th>Accès</th>
                    <th>Date d'inscription</th>
                  </tr>
                </thead>
                <tbody>

                  {members.map((member) => {
                    return (
                      <Userline
                        id={member.id}
                        key={member.id}
                        lastName={member.data.lastName}
                        firstName={member.data.firstName}
                        email={member.data.email}
                        role={member.data.role}
                        autorisation={member.data.autorisation}
                        creationDate={new Date(member.data.creationDate.seconds * 1000).toLocaleDateString("fr")}

                      />
                    )
                  })}

                </tbody>
              </Table>

              <button className='addMember' onClick={() => setModalShow(true)}>Ajouter un membre</button>
            </div>
          </div>

        </Dashboard>
  )
}

export default Members