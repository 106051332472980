import React, { useEffect, useState } from 'react';
import './rubriques.css';
import Dashboard from '../../Dashboard'
import { Modal, Table } from 'react-bootstrap';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { firestoreDatabase, storage } from '../../firebase';
import { Timestamp, addDoc, collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import Rubriqueline from './Rubriqueline';

function MyVerticallyCenteredModal(props) {
  const [nom, setNom] = useState("");
  const [couleur, setCouleur] = useState("");
  const [error, setError] = useState('');

  const [file, setFile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

    const addNewRubrique = async (event) => {
      try {
        event.preventDefault();
        setIsLoading(true); // Afficher le loader

        // Télécharger lacover sur Firebase Storage
        const fileRef = ref(storage, `rubriques//${file.name}`);
        await uploadBytes(fileRef, file);
  
        // Récupérer l'URL de téléchargement de la photo de profil
        const downloadURL = await getDownloadURL(fileRef);

        // Adding to Firebase Firestore
        await addDoc(collection(firestoreDatabase, "rubriques"), {
          cover: downloadURL,
          nom: nom,
          couleur: couleur,
          createdAt: Timestamp.now()
        });

        setShowSuccess(true);
      } catch (error) {
        setError(error);
      }
    };
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };

return (
  <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
      Ajouter une rubrique
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className='row justify-content-center'>
    {error && <div className='auth__error'>{error}</div>}
    {showSuccess ? (
      <center id='successmessage'>
        <p>Nouvelle rubrique ajoutée !</p>
        <button type='button' className="btn-form" onClick={props.onHide}>OK</button>
      </center>
    ) : (
      <form onSubmit={addNewRubrique} name='addRubrique_form' className='col-md-12' id='addRubriqueform'>
      <center>

        <input
          type="text"
          placeholder="Nom"
          value={nom}
          onChange={e => setNom(e.target.value)}
          required/>
          
        <input
          type="text"
          placeholder="Couleur"
          value={couleur}
          onChange={e => setCouleur(e.target.value)}
          required/>

        <input 
          type="file" 
          onChange={handleFileChange}
          required/>

        <button type='submit' className="btn-form">
        {isLoading ? (
            <i className="fa fa-circle-o-notch fa-spin"/>
            ) : (
            'Créer'
        )}
        </button>
        
      </center>
    </form>
    )}
    </Modal.Body>
  </Modal>
);
}

function Rubriques() {

  const [rubriques, setRubriques] = useState([])

  //read users data from firestore
  useEffect(() => {
  const retrieveUsersData = async () =>{
    const userColRef = query(collection(firestoreDatabase, 'rubriques'), orderBy('nom', 'asc'))
    onSnapshot(userColRef, (snapshot) => {
      setRubriques(snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
  }

  retrieveUsersData();
    }, []);

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Dashboard>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <div className="row" id='rubriques'>

        <div className="col-md-1"></div>

        <div className="col-md-10">

          <Table hover className="list_table">
            <thead>
              <tr>
                <th hidden={true}>id</th>
                <th>Rubriques</th>
              </tr>
            </thead>
            <tbody>

              {rubriques.map((rubrique) => {
                return (
                  <Rubriqueline
                    id={rubrique.id}
                    key={rubrique.id}
                    nom={rubrique.data.nom}
                  />
                )
              })}

            </tbody>
          </Table>

        </div>
        
      </div>


      <button className='addRubrique' onClick={() => setModalShow(true)}>Ajouter une rubrique</button>

    </Dashboard>
  )
}

export default Rubriques