import React, { useEffect, useState } from 'react';
import './myprofile.css';
import Dashboard from '../../Dashboard';
import { auth, firestoreDatabase, storage } from '../../firebase';
import { collection, doc, getDocs, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import EditMyProfile from './EditMyProfile';
import { MdPhotoCamera } from 'react-icons/md';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { Modal } from 'react-bootstrap';

 
function MyVerticallyCenteredModal(props) {
    //update picture
    const user = auth.currentUser;

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);
  
    const updateUserProfilePicture = (e) => {
      e.preventDefault()
      const file = e.target[0]?.files[0]
  
      if (!file) return;
  
      const storageRef = ref(storage, `family/profil/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setImgUrl(downloadURL);
      });

      uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgresspercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL);
  
            const userDocRef = doc(firestoreDatabase, 'family', user.uid)
            try {
                 updateDoc(userDocRef, {
                    imgprofil: downloadURL,
                    lastModif: Timestamp.now()
                })
                // window.location.reload();
            } catch (error) {
                alert(error)
            }
          });
        }
      );
      }

      const myNewProfilPicture={backgroundImage:`url(${imgUrl})`};

      const closeAndUpdate = () => {
        props.onHide();
        window.location.reload();
      }
    
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifier ma photo de profil
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={updateUserProfilePicture}>
            <center>
              {
              !imgUrl &&
                <input 
                type='file'
                accept="image/png, image/jpeg"
                required/>
              }

              {
              !imgUrl &&
                <div className='row d-flex justify-content-center'>
                    <button 
                    className='btn_submit_modify col-md-2' 
                    type='submit'
                    >Modifier</button>
                </div>
              }

              {
              imgUrl &&
                <div className='row d-flex justify-content-center'>
                    <div  className="profil_picture" style={myNewProfilPicture}/>
                    <div className='row d-flex justify-content-center'>
                      <div className='outerbar col-md-4'>
                        <div className='innerbar' style={{ width: `${progresspercent}%` }}></div>
                        <p>{progresspercent}%</p>
                      </div>
                    </div>
                    <button 
                    onClick={closeAndUpdate}
                    className='btn_submit_modify col-md-2'
                    style={{marginTop: `0`}}
                    >OK</button>
                </div>
              }

            </center>
        </form>

      </Modal.Body>
    </Modal>
  );
}

function MyProfile() {

  const user = auth.currentUser;
  const [firstName] = useState("");
  const [lastName] = useState("");


  const [open, setOpen] = useState({edit:false});

  const [modalShow, setModalShow] = React.useState(false);

  const handleClose = () => {
    setOpen({edit:false});
  }

    //Récupérer les données de l'utilisateur
    const [userData, setUserData] = useState(null);

    useEffect(() => {
      const fetchUserData = async () => {
        const q = query(collection(firestoreDatabase, "family"), where("email", "==", user.email))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setUserData(doc.data());
        })
      }; 
      fetchUserData();
    }, [user]);

    /*Ajouter une photo de profil*/
  const [file, setFile] = useState(null);
   
  const handleSubmit = async (event) => {

    // function hasSpecialCharacters(input) {
    //   const regex = /[^a-zA-Z0-9]/g;
    //   return regex.test(input);
    // }
    // const contientCaracteresSpeciaux = hasSpecialCharacters(file.name);


    // if (file.search(/[<>'s+";`%]/)>0) {
    //   // Le input contient des caractères spéciaux
    //   alert("caractères spéciaux détectés")
    // } else {
      try {
        // Le input ne contient pas de caractères spéciaux
        event.preventDefault();

        // Télécharger la photo de profil sur Firebase Storage
        const fileRef = ref(storage, `family/profil//${file.name}`);
        await uploadBytes(fileRef, file);

        // Récupérer l'URL de téléchargement de la photo de profil
        const downloadURL = await getDownloadURL(fileRef);

        // Enregistrer l'URL de téléchargement dans Firestore
        const userRef =  doc(firestoreDatabase, 'family', user.uid);
        await updateDoc(userRef, {
          imgprofil: downloadURL,
          lastModif: Timestamp.now()
        });

        window.location.reload()
      } catch (error) {
        
      }

    // }

  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };


    const myProfilPicture={backgroundImage:`url(${userData?.imgprofil})`};

  
  return (
      <Dashboard>
        <div id='userprofil_container' className=''>

          <div className='row d-flex justify-content-center'>

            {userData?.imgprofil ? (
              
              <div className="profil_picture" style={myProfilPicture}>
                <div className='updatepic hide'
                  onClick={() => setModalShow(true)}
                >
                  <center>
                    <MdPhotoCamera />
                  </center>
                </div>
              </div>          

            ) : (

              <div className='add_profil_pic'>
                <center>
                  <form onSubmit={handleSubmit}>
                    <label>
                      Choisissez une photo de profil :
                      <input type="file" onChange={handleFileChange} />
                    </label>
                    <button type="submit">Télécharger</button>
                  </form>
                </center>
              </div>

            )}

            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />

          </div>

          <hr/>

          <div className='row info'>

            <div className='col-md-6'>
      
              <p className='info_type'>Mes informations personnelles</p>

              <p>Nom & Prénoms: <span>{userData?.lastName} {userData?.firstName}</span></p>
              <p>Email: <span>{userData?.email}</span></p>
            </div>

          </div>
          
        </div>

        <div className='row d-flex justify-content-center'>
          <button 
          className='btn_modify col-md-2'
          onClick={() => setOpen({...open, edit : true})}
          >Modifier mes informations</button>
        </div>

        {open.edit &&
        <EditMyProfile
        onClose={handleClose}

        toEditFirstname={firstName}
        toEditLastname={lastName}

        open={open.edit}
        userid={user.uid}
        />}

      </Dashboard>
  )
}

export default MyProfile