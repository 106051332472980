import React, { useState } from 'react';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { firestoreDatabase } from '../../firebase';
import Xmodal from './../tools/xmodal/Xmodal';

function EditMyProfile({open, onClose, toEditFirstname, toEditLastname, userid}) {
 
    const [firstName, setFirstName] = useState(toEditFirstname);
    const [lastName, setLastName] = useState(toEditLastname);

    //update profil function
    const updateProfil = async (e) => {
        e.preventDefault();
        const userDocRef = doc(firestoreDatabase, 'family', userid)
        try {
            await updateDoc(userDocRef, {
                firstName: firstName,
                lastName: lastName,
                lastModif: Timestamp.now()
            })
            window.location.reload();
            onClose();
        } catch (error) {
            alert(error)
        }
    }
  
  return (
    <Xmodal xmodalLabel={'Modifier mes informations'} onClose={onClose} open={open}>
        <form onSubmit={updateProfil} className="updateprofil" name='updateprofil'>
            <center>
                <input
                type="text"
                placeholder="Nom"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required />

                <input
                type="text"
                placeholder="Prénoms"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}                
                required/>

                <div className='row d-flex justify-content-center'>

                    <button 
                    className='btn_submit_modify col-md-2' 
                    type='submit'
                    >Modifier</button>

                </div>

            </center>
        </form>
    </Xmodal>
  )
}

export default EditMyProfile