import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCWdfDVDhok8DR_k5sEZlFeOqcel-3A6Hc",
  authDomain: "connect-694316.firebaseapp.com",
  projectId: "connect-694316",
  storageBucket: "connect-694316.appspot.com",
  messagingSenderId: "894373597882",
  appId: "1:894373597882:web:1ec61bdeee9feddf5aa883",
  measurementId: "G-Z1TVJTPBPZ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestoreDatabase = getFirestore(app);
const dbz = getFirestore(app);
const storage = getStorage(app);
const db = getDatabase(app);

const google_provider = new GoogleAuthProvider();
google_provider.setCustomParameters({ prompt: 'select_account' });

const apple_provider = new OAuthProvider('apple.com');
apple_provider.setCustomParameters({
  locale: 'fr'
});

export { auth, firestoreDatabase, dbz, storage, db, google_provider, apple_provider };